<template>
  <section id="section-about">
    <h1>{{ title }}</h1>
  </section>
</template>

<script>
export default {
  data () {
    return {
      title: 'About Monoshark'
    }
  }
}
</script>
