<template>
  <section id="section-home">
    <h1>{{ title }}</h1>
    <PostListItem v-for="(post, index) in postList" :post="post" :key="index" />
  </section>
</template>

<script>
import PostListItem from '@/components/PostListItem.vue'

export default {
  name: 'HomeView',
  components: {
    PostListItem
  },
  data () {
    return {
      title: 'Blog',
      postList: [
        {
          title: 'Welcome to Monoshark',
          body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad harum repudiandae repellat minima nihil distinctio consequatur, expedita, quidem ipsam, doloremque qui officiis nam debitis soluta quasi earum. A, repellat accusantium.'
        }
      ]
    }
  }
}
</script>
