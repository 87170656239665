<template>
  <header id="main-header">
    <h1 class="main-header-title">{{ header }}</h1>
  </header>
  <main-navigation/>
  <router-view/>
  <footer id="main-footer">
    <div class="copyright">{{ copyright }}</div>
  </footer>
</template>

<script>
import MainNavigation from '@/components/MainNavigation.vue'

export default {
  name: 'app',
  components: {
    MainNavigation
  },
  data () {
    return {
      header: 'Monoshark',
      copyright: `Copyright © ${(new Date()).getFullYear()} Monoshark. All rights reserved`
    }
  }
}
</script>

<style>
:root {
  color-scheme: light dark;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
