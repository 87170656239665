<template>
  <article class="post-list-item">
    <h2 class="post-title">{{ post.title }}</h2>
    <p class="post-body">{{ post.body }}</p>
    <small><router-link to="#">{{ readMore }}</router-link></small>
  </article>
</template>

<script>
export default {
  name: 'post-list-item',
  props: ['post'],
  data () {
    return {
      readMore: 'read more ...'
    }
  }
}
</script>

<style scoped>
a {
  color: #262727;
}

a.router-link-exact-active {
  color: #d7d8d7;
}
</style>
