<template>
  <nav id="main-navigation">
    <router-link to="/">Home</router-link>
    <router-link to="/About">About</router-link>
  </nav>
</template>

<script>
export default {
  name: 'main-navigation'
}
</script>

<style scoped>
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #535455;
  margin: 0px 20px;
}

nav a.router-link-exact-active {
  color: #989a99;
}
</style>
